import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/Image';
import ProtectedViewImage from './Flooding.jpeg';
import BlockWrapper, {
  ContentWrapper,
  ImageWrapper,
} from 'containers/shared/imageright/humanityBlock.style';
import {Link} from "gatsby";
import PDFImage from './pdf-tweet-lammas.png';

const HumanityBlock = ({ row, col }) => {
  return (
    <BlockWrapper id="socialFundraising">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col text-container" {...col}>
            <ContentWrapper>
              <Heading content="As a Flood Defence" />
              <p>
                <strong>Significant flooding</strong> has occurred in Godalming in 1968, 1990, 2000, 2013 and 2020. These events are
                getting closer together with climate change. During the 2013 flood, <strong>84 properties</strong> in the Meadrow and Catteshall area
                were affected and homeowners evacuated. <strong>Residents were out of their homes for many months.</strong>
              </p>
              <p>
                Any act, such as the ones proposed, driving posts to support a boardwalk, foundations or partially surfacing the area will lead to <strong>reduced flood storage</strong>, this
                is <strong>not a risk (however small) that we should be willing take!</strong> <a href="/local-policy.pdf" target="_blank">Policy CC4 of the local plan</a> specifically states that any construction should not constrain the <strong>natural function of the functional floodplain</strong>, either by
                impeding flood flow or reducing storage capacity.
              </p>
              <p>
                We are unsure that this <a href="https://www.gov.uk/guidance/flood-risk-and-coastal-change#flood-zone-and-flood-risk-tables" target="_blank"> could even be classified</a> as the kind of essential infrastructure in a (Zone 3B) floodplain that would be <strong>recommended by the environment agency</strong> and that it would meet the essential criteria after undertaking the Sequential and Exception Tests. <strong><Link to="/our-response/flood-policy/">Read our in depth analysis as to why we believe this to go against Environment Agency guidance here</Link></strong>.
              </p>
              <p>
                The Lammas Lands are recognised as a <strong>vital part of the flood protection measures of the town</strong> and even a marginal increase in flood risk <strong>is not acceptable</strong>. The Environment Agency dictates <strong>no net loss</strong> of floodplain storage, at the council(s) own admission this project will <strong>lead to a marginal reduction.</strong>
              </p>
              <Link className="learn__more-btn" to="/flood-defence">
                <span className="hyphen" />
                <span className="btn_text">Learn more about the Flood Defences</span>
              </Link>
            </ContentWrapper>
          </Box>
          <Box className="col image-container" {...col}>
            <ImageWrapper>
              <GatsbyImage
                src={ ProtectedViewImage }
                alt="Lammas Lands Flooding"
              />

              <GatsbyImage
                style={{ marginTop: '2em' }}
                src={ PDFImage }
                alt="Paul David Follows tweet of Lammas Lands flooding"
              />
            </ImageWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// HumanityBlock style props
HumanityBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// HumanityBlock default style
HumanityBlock.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // HumanityBlock col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default HumanityBlock;
