import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/Image';
import ProtectedViewImage from './image.png';
import BlockWrapper, {
  ContentWrapper,
  ImageWrapper,
} from 'containers/shared/imageleft/humanityBlock.style';

const HumanityBlock = ({ row, col, colImage }) => {
  return (
    <BlockWrapper id="socialFundraising">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col image-container" {...colImage}>
            <ImageWrapper>
              <GatsbyImage
                src={ ProtectedViewImage }
                alt="Charity Landing"
              />
            </ImageWrapper>
          </Box>
          <Box className="col text-container" {...col}>
            <ContentWrapper>
              <Heading content="The Proposed Plans" />
              <p>
                Two options for the Lammas Lands currently exist under the current proposal, we believe <strong>neither to be ecologically sound</strong>, compromising natural habitats for logically unsound green points.
              </p>
              <p>
                A <strong>board walk</strong> of approximately <strong>3 - 4 metres in width</strong> is proposed as an option across the <strong>Overgone Meadow</strong> (Lammas Lands) to the west of Bridge Rd.
              </p>
              <p>
                Another option is to provide a <strong>bound 3 metres wide path</strong> (buff coloured surface) across the Lammas Lands <strong>between Catteshall Road and Bridge Road</strong>, just south of Godalming United Church.
              </p>
              <a className="learn__more-btn" href="/route-plan.pdf" target="_blank">
                <span className="hyphen" />
                <span className="btn_text">View the proposed route plan</span>
              </a>
            </ContentWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// HumanityBlock style props
HumanityBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// HumanityBlock default style
HumanityBlock.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // HumanityBlock col default style
  col: {
    width: ['100%', '50%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
  colImage: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default HumanityBlock;
