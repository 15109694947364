import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/Image';
import ProtectedViewImage from './image.png';
import BlockWrapper, {
  ContentWrapper,
  ImageWrapper,
} from 'containers/shared/imageright/humanityBlock.style';

const HumanityBlock = ({ row, col }) => {
  return (
    <BlockWrapper id="socialFundraising">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col image-container" {...col}>
            <ImageWrapper>
              <GatsbyImage
                src={ ProtectedViewImage }
                alt="Charity Landing"
              />
            </ImageWrapper>
          </Box>
          <Box className="col text-container" {...col}>
            <ContentWrapper>
              <Heading content="Protected View of St Peter and St Paul Parish Church" />
              <p>
                One aspect raised by the community through the <a href="/godalming-neighbourhood-plan.pdf" target="_blank">Godalming and Farncombe Neighbourhood Plan (2019) </a>
                engagement process was the <strong>importance of protecting valued landmark views.</strong>
              </p>
              <p>
                The plan sets out that development should <strong>preserve the historic setting</strong> of the panoramic view from Chalk
                Road/Bridge Road towards the Parish Church of St Peter and St Paul, across the
                Lammas Lands. Any development which has a <strong>detrimental impact on the setting of this panoramic view will be refused.</strong>
              </p>
              <p>
                We sincerely hope that Waverley Borough Council will not <strong>go against</strong> what was set out as part of the Neighbourhood Plan.
              </p>
            </ContentWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// HumanityBlock style props
HumanityBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// HumanityBlock default style
HumanityBlock.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // HumanityBlock col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default HumanityBlock;
