import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/Image';
import ProtectedViewImage from './communting.jpeg';
import BlockWrapper, {
  ContentWrapper,
  ImageWrapper,
} from 'containers/shared/imageright/humanityBlock.style';

const HumanityBlock = ({ row, col }) => {
  return (
    <BlockWrapper id="socialFundraising">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col text-container" {...col}>
            <ContentWrapper>
              <Heading content="Green Benefits" />
              <p>
                Surrey County Council have stated the following about the route
              </p>
              <p style={{ fontStyle: 'italic' }}>
                "While the route has been selected with utility in mind, most of it passes <strong>through scenic landscape </strong>
                making it suitable for leisure as well. However it is <strong>not intended that this should be a route for cycling at speed</strong>. <strong>Fast routes for commuting</strong> by bike may be better <strong>provided separately.</strong>"
              </p>
              <p>
                Our opinion is that of the council(s) own admission, this route would <strong>not be suitable</strong> for the kind of commuting that it is promising and the <strong>reduction in carbon emissions</strong> that would come from it.
                This juxtaposed with the potential <strong>harm to the floodplain function</strong>, surrounding <strong>views</strong> and <strong>natural habits</strong> make this an <strong>inadequate response to decreasing daily car usage.</strong>
              </p>
            </ContentWrapper>
          </Box>
          <Box className="col image-container" {...col}>
            <ImageWrapper>
              <GatsbyImage
                src={ ProtectedViewImage }
                alt="Charity Landing"
              />
            </ImageWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// HumanityBlock style props
HumanityBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// HumanityBlock default style
HumanityBlock.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // HumanityBlock col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default HumanityBlock;
