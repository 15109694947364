import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/Image';
import ProtectedViewImage from './image.png';
import BlockWrapper, {
  ContentWrapper,
  ImageWrapper,
} from 'containers/shared/imageleft/humanityBlock.style';
import {Link} from "gatsby";

const HumanityBlock = ({ row, col }) => {
  return (
    <BlockWrapper id="socialFundraising">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col image-container" {...col}>
            <ImageWrapper>
              <GatsbyImage
                src={ ProtectedViewImage }
                alt="Charity Landing"
              />
            </ImageWrapper>
          </Box>
          <Box className="col text-container" {...col}>
            <ContentWrapper>
              <Heading content="As common land" />
              <p>
                The Lammas lands form an <strong>important landscape feature</strong> in the town. Residential properties look down on
                the meadows from the north and south and there are views of local churches, the river Wey and the town from the meadows.
              </p>
              <p>
                Under the <strong>Commons Act</strong>, it is the <strong>duty of the Waverley Council</strong> to ensure that the <strong>special qualities</strong> of common land,
                including its <strong>open and unenclosed nature</strong>, are properly <strong>protected</strong>. A key part of their role being to ensure that local people will not be prevented from using it in the way they are used to.
              </p>
              <p>
                Building cycle infrastructure across common land and change of use will require <strong>authorization from the Secretary of State</strong> under section 38 of the Commons Act and should not be undertaken lightly. The paths
                will also require necessary public infrastructure (such as bins, waste management and lighting) to ensure security and maintain the pristine natural habitat.
              </p>
              <p>
                There is huge concern that this proposal sets a <strong>future precedent</strong> for how the council can use these protected areas.
              </p>

              <Link className="learn__more-btn" to="/resources">
                <span className="hyphen" />
                <span className="btn_text">View all related official documentation</span>
              </Link>
            </ContentWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// HumanityBlock style props
HumanityBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// HumanityBlock default style
HumanityBlock.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // HumanityBlock col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default HumanityBlock;
