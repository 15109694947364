import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/Image';
import ProtectedViewImage from './Lapwing.jpeg';
import BlockWrapper, {
  ContentWrapper,
  ImageWrapper,
} from 'containers/shared/imageright/humanityBlock.style';
import {Link} from "gatsby";

const HumanityBlock = ({ row, col }) => {
  return (
    <BlockWrapper id="socialFundraising">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col text-container" {...col}>
            <ContentWrapper>
              <Heading content="Protection of Wildlife" />
              <p>
                A total of <strong>108 species of flowering plants</strong> were recorded from the site in 1994, and <strong>227 invertebrates</strong>, were recorded from the site in 2001.
              </p>
              <p>
                <strong>Six amber and twelve red BCC listed bird species</strong> have been recorded and the site has considerable potential to support additional numbers and species of bird.
              </p>
              <p>
                The ecologist led <a href="/management-plan-2017.pdf" target="_blank">management plan</a> emphasises the need for <strong>“suitable habitat and disturbance-free conditions”</strong>
                and that <strong>“cyclists should continue to be confined to the tow path”.</strong>
              </p>
              <p>For a point by point breakdown as to why we believe this is at odds with the management plan, <strong><Link to="/our-response/management-plan/">we have outlined it in detail here</Link></strong> (on request from Councillor Richard Ashworth).</p>
              <Link className="learn__more-btn" to="/wildlife">
                <span className="hyphen" />
                <span className="btn_text">Learn more about The wildlife</span>
              </Link>
            </ContentWrapper>
          </Box>
          <Box className="col image-container" {...col}>
            <ImageWrapper>
              <GatsbyImage
                src={ ProtectedViewImage }
                alt="Charity Landing"
              />
            </ImageWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// HumanityBlock style props
HumanityBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// HumanityBlock default style
HumanityBlock.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // HumanityBlock col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default HumanityBlock;
