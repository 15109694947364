import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/Image';
import ProtectedViewImage from './land-registry.png';
import BlockWrapper, {
  ContentWrapper,
  ImageWrapper,
} from 'containers/shared/imageright/humanityBlock.style';

const HumanityBlock = ({ row, col }) => {
  return (
    <BlockWrapper id="socialFundraising">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col text-container" {...col}>
            <ContentWrapper>
              <Heading content="Restrictive Covenants" />
              <p>
                Surrey County Council appear to be ignoring <a href="/RegisterSY644883.pdf" target="_blank">the restrictive covenants</a> in place at Land Registry in their plans for a boardwalk on Overgone Meadow. The key covenant states that,
              </p>
              <p style={{ fontStyle: 'italic', fontWeight: 700 }}>
                "not at any time hereafter either to change the use from that of agriculture or to take any action which would encourage public use of
                the said property (edged red excluding the 4.35 acres between Chalk Road and Hells Ditch)."
              </p>
              <p>
                These restrictive covenants, in the words of a member of the family that put them in place, were put there to ensure that they remain as farming, grazing and wildlife land. They are very keen for it to stay that way.
              </p>
            </ContentWrapper>
          </Box>
          <Box className="col image-container" {...col}>
            <ImageWrapper>
              <GatsbyImage
                src={ ProtectedViewImage }
                alt="Charity Landing"
              />
            </ImageWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// HumanityBlock style props
HumanityBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// HumanityBlock default style
HumanityBlock.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // HumanityBlock col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default HumanityBlock;
