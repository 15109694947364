import React from 'react';
import LeftBar from './leftBar';
import BannerWrapper, {
  ContentWrapper,
  TextArea,
  ImageArea,
} from 'containers/shared/banner/bannerSection.style';

import BannerImage from './banner-image.png';

const BannerSection = () => {
  return (
    <BannerWrapper>
      <LeftBar text="SCROLL DOWN" offset={81} sectionId="#feature" />
      <ContentWrapper>
        <TextArea>
          <h2>
            Godalming Greenway <br/><small>Lammas Lands</small>
          </h2>
          <p>
            The local councils have proposed to build a <strong>4m wide compound or raised cycle track</strong> through the Lammas Lands and across the river wey.
          </p>
          <p>
            The proposed development would flow traffic directly from the A3100 down into this <strong>designated site of nature conservation importance</strong>, and we believe would be a <strong>disaster for this protected area.</strong>
          </p>
          <p>
            This sets a <strong>dangerous precedent</strong> for the future use of our <strong>protected common land</strong>. The estimated project cost is between £1.5m - £3.5m, with costing for the Lammas Lands portions currently not being available, at least in the public domain.
          </p>
        </TextArea>
        <ImageArea style={{ backgroundImage: `url(${BannerImage})` }} />
      </ContentWrapper>
    </BannerWrapper>
  );
};

export default BannerSection;
