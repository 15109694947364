import React from 'react';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import BlockWrapper, {
  CounterWrapper,
  CounterItem,
} from 'containers/shared/counterblock/milestoneBlock.style';

const MilestoneBlock = () => {
  return (
    <Container id="milestone" width="1260px">
      <BlockWrapper>
        <h2>What people use them for</h2>
        <CounterWrapper>
          <CounterItem>
            <Heading as="h3" content="46%" />
            <Text content="Walking" />
          </CounterItem>
          <CounterItem>
            <Heading as="h3" content="24%" />
            <Text content="Dog Walking" />
          </CounterItem>
          <CounterItem>
            <Heading as="h3" content="13%" />
            <Text content="Family Outing" />
          </CounterItem>
          <CounterItem>
            <Heading as="h3" content="17%" />
            <Text content="Other" />
          </CounterItem>
        </CounterWrapper>
      </BlockWrapper>
    </Container>
  );
};

export default MilestoneBlock;
