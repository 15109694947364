import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { charityTheme } from 'common/theme/charity';
import { ResetCSS } from 'common/assets/css/style';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import Navbar from 'containers/Charity/Navbar';
import DrawerSection from 'containers/Charity/DrawerSection';
import BannerSection from 'containers/Greenway/BannerSection';
import ProtectedView from 'containers/Greenway/ProtectedView';
import CTAOne from 'containers/Greenway/CallToActionOne';
import Wildlife from 'containers/Greenway/Wildlife';
import VisitorStats from 'containers/Home/VisitorStats';
import FloodDefence from 'containers/Greenway/FloodDefence';
import ManagementStats from 'containers/VisitorDemographics/ManagementStats';
import ThePlan from 'containers/Greenway/ThePlan';
import Stats from 'containers/Home/Stats';
import GodalmingSoul from 'containers/Greenway/GodalmingSoul';
import ReasonForVisit from 'containers/Greenway/ReasonForVisit';
import BranchSection from "containers/Greenway/BranchSection";
import Communting from "containers/Greenway/Commuting";
import Footer from 'containers/Charity/Footer';
import PriorConsultations from 'containers/Greenway/PriorConsultations';
import RestrictiveCovenants from 'containers/Greenway/RestrictiveCovenants';

import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from 'containers/Charity/charity.style';
import Seo from 'components/seo';


const Index = () => {
  return (
    <ThemeProvider theme={charityTheme}>
      <Fragment>
        <Seo
          title="Godalming Greenway"
          description="Information on why we deeply oppose the Guildford & Godalming Greenway, why it is a direct risk
          to flood management and the greater ecology of the meadows"
        />
        <ResetCSS />
        <GlobalStyle />
        {/* Start charity wrapper section */}
        <CharityWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <Navbar />
          </Sticky>
          <DrawerProvider>
            <DrawerSection />
          </DrawerProvider>
          <ContentWrapper>
            <BannerSection />
            <ReasonForVisit />
            <ThePlan />
            <Stats />
            <Wildlife />
            <VisitorStats />
            <GodalmingSoul />
            <CTAOne />
            <FloodDefence />
            <ManagementStats />
            <ProtectedView />
            <Communting />
            <PriorConsultations />
            <RestrictiveCovenants />
            <BranchSection />
            <CTAOne />
            <Footer />
          </ContentWrapper>
        </CharityWrapper>
        {/* End of charity wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};
export default Index;
