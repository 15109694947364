import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/Image';
import ProtectedViewImage from './image.png';
import BlockWrapper, {
  ContentWrapper,
  ImageWrapper,
} from 'containers/shared/imageright/humanityBlock.style';

const HumanityBlock = ({ row, col }) => {
  return (
    <BlockWrapper id="socialFundraising">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col image-container" {...col}>
            <ImageWrapper>
              <GatsbyImage
                src={ ProtectedViewImage }
                alt="Charity Landing"
              />
            </ImageWrapper>
          </Box>
          <Box className="col text-container" {...col}>
            <ContentWrapper>
              <Heading content="Prior Consultations" />
              <p>
                As part of the <a href="/godalming-neighbourhood-plan.pdf" target="_blank">Godalming and Farncombe Neighbourhood Plan (2019) </a>
                traffic calming measures were suggested and have not been implemented, specifically on Bridge Road.
              </p>
              <p>
                The ecologist led <a href="/management-plan-2017.pdf" target="_blank">management plan</a> and <a href="/visitor-survey-2016.pdf" target="_blank">visitor survey </a>
                consulted 219 people who actually use the Lammas Lands, rather than cyclists from Guildford. The overriding sentiment from these was that the key focuses should be <strong>habitat management</strong>, <strong>floodplain management</strong> and <strong>cattle grazing</strong>.
                They also suggested extra turnstiles and volunteer groups, to help locals become more educated about their surrounding nature.
              </p>
              <p>
                It is important that these <strong>previous consultations & expert advice</strong> are undertaken and that the <strong>results and advice are implemented</strong>.
              </p>
            </ContentWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// HumanityBlock style props
HumanityBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// HumanityBlock default style
HumanityBlock.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // HumanityBlock col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default HumanityBlock;
