import React from 'react';
import Fade from 'react-reveal/Fade';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Tab, { Panel } from 'common/components/Tabs';
import Image from 'common/components/Image';
import SectionWrapper, { ContentWrapper } from './branchSection.style';

import PaulFollowsImage from './paul-follows.jpeg';
import JeremyHuntImage from './jeremy-hunt.jpeg';
import GodalmingTownCouncil from './gtc.jpeg';
import WaverleyBouroughCouncil from './wbc.jpg';
import SurreyCountyCouncil from './scc.jpg';

const BranchSection = () => {
  const replies = [
    {
      id: 1,
      status: 'full support given',
      name: 'Paul Follows',
      title: '"I’ve backed this from the moment I’ve known about it (and to be blunt I’m one of the reasons it has funding from various places)".<br/><br/>"The waverley sections have the full support of the two councils I lead (Godalming town council and waverley borough council), the support of the waverley Lib Dems and me personally."',
      description: 'The above comment(s) appear to be regarding the entire project (and were taken from facebook), we have sought clarification from Paul. Paul is both the leader of Godalming Town & Waverley councils and is also a member of Surrey County Council. Paul is a resident of Godalming and a member of the Liberal Democrats.',
      image: PaulFollowsImage,
    },
    {
      id: 2,
      status: 'awaiting consultation response',
      name: 'Jeremy Hunt',
      title: 'We have spoken to Jeremy alongside the Godalming Flood Group at his office. We ran through the issues with his team and him around flooding, nature and the issues with the overall plan. <br/><br/>He visited the flood victims after 2013 and again once the flood defences had been built. We await the publication of his official stance.',
      description: 'Jeremy Hunt is the Conservative MP for South West Surrey, and has been an MP continuously since 5 May 2005.',
      image: JeremyHuntImage,
    },
    {
      id: 3,
      status: 'received all information',
      name: 'Godalming Town Council',
      title: 'Under the freedom of information act we have requested all information, documents and internal communications held by the council relating to the Greenway, held on their systems or in paper form.',
      description: `We have received all data held within their systems, these can be viewed on <a href="https://www.whatdotheyknow.com/request/godalming_greenway#outgoing-1219673" target="_blank">here on whatdotheyknow.com</a>. The most interesting being the report created to asses the Burys & Memorial Park for a cycleway.`,
      image: GodalmingTownCouncil,
    },
    {
      id: 4,
      status: 'awaiting information',
      name: 'Waverley Borough Council',
      title: 'Under the freedom of information act we have requested all information, documents and internal communications held by the council relating to the Greenway, held on their systems or in paper form.',
      description: 'We are awaiting this information from the council, they are within the acceptable response period, especially for a request of this size.  <br/><br/><a href="https://www.whatdotheyknow.com/request/godalming_guildford_greenway#incoming-1904529" target="_blank">View the request here whatdotheyknow.com</a>',
      image: WaverleyBouroughCouncil,
    },
    {
      id: 5,
      status: 'awaiting information',
      name: 'Surrey County Council',
      title: 'Under the Environmental Information Regulations we have requested all information, documents and internal communications held by the council relating to the Greenway, held on their systems or in paper form.',
      description: 'We are awaiting this information from the council, they are within the acceptable response period, especially for a request of this size.  <br/><br/><a href="https://www.whatdotheyknow.com/request/godalming_guildford_greenway_2#incoming-1904306" target="_blank">Voew the request here whatdotheyknow.com</a>',
      image: SurreyCountyCouncil,
    },
  ];

  const title = (text) => {
    return { __html: text };
  };

  return (
    <SectionWrapper id="branch">
      <Tab active={0}>
        {replies.map((item) => (
          <Panel
            title={<Text content={item.name} s />}
            key={`tab_key${item.id}`}
          >
            <ContentWrapper>
              <Fade>
                <div className="image">
                  <Image src={item.image} alt={`${item.name} image`} />
                </div>
              </Fade>
              <div className="content">
                <Heading as="h4" content={item.status} style={{ fontWeight: 700, textTransform: 'uppercase' }} />
                <p dangerouslySetInnerHTML={{ __html: item.title }} style={{ fontWeight: 700 }} />
                <p dangerouslySetInnerHTML={{ __html: item.description }} />
              </div>
            </ContentWrapper>
          </Panel>
        ))}
      </Tab>
    </SectionWrapper>
  );
};

export default BranchSection;
